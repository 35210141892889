<template>
  <div ref="indexContent" class="home-container" :class="{'home-bg': $store.state.themeInfo.type!==3 }" :style="{ height: $store.state.themeInfo.type === 1 ? '100%' : '', overflowX: 'hidden' }">
    <template v-if="$store.state.themeInfo.type === 2">
      <head-menu />
<!--      <user-content style="margin-top:10px" />-->
      <live-content />
      <!-- <daka-content /> -->
      <news-content />
      <video-content />
    </template>
    <template v-else-if="$store.state.themeInfo.type === 1">
      <head-menu />
    </template>
    <!-- 海报类 -->
    <template v-else>
      <index-lite />
    </template>
  </div>
</template>

<script>
import headMenu from './components/headMenu'
import newsContent from './components/newsContent'
import videoContent from './components/videoContent'
import liveContent from './components/liveContent'
import userContent from './components/userContent'
// import dakaContent from './components/dakaContent'
import indexLite from "@/views/indexLite/index"
import UserContent from './components/userContent.vue'
export default {
  name: 'home',
  components: { headMenu, liveContent, newsContent, videoContent, indexLite, userContent }
}
</script>

<style lang="less" scoped>
.home-container{
  position: relative;
  // background: linear-gradient(to top, rgb(16, 24, 63), rgb(15, 40, 99)) url('~@/assets/images/home-bg.png') center/100% auto no-repeat;
  // background: rgb(16, 24, 63);
  // background-color: #1c3876;
  // background: url('https://sn202108-1305501521.cos.ap-shanghai.myqcloud.com/202208231817209411561.jpeg?imageView2/2/w/375') no-repeat;
  // // background-position-y: 180px;
  // background-size: 100% 100%;
  // &::before{
  //   content: '';
  //   width: 100%;
  //   position: fixed;
  //   background: url('~@/assets/images/home-bg.png') center/120% 200px no-repeat;
  //   left: 0;
  //   top: 200px;
  //   height: 300px;
  // }
  // &::after{
  //   content: '';
  //   width: 100%;
  //   position: fixed;
  //   background: url('~@/assets/images/home-bg.png') center/120% 200px no-repeat;
  //   left: 0;
  //   bottom: -30px;
  //   height: 300px;
  // }
}
.home-bg {
  background: url('https://sn202108-1305501521.cos.ap-shanghai.myqcloud.com/202208241504595655760.jpeg?imageView2/2/w/375') no-repeat;
  background-size: 100% 100%;
}
</style>