<template>
  <div class="page-wapper">
    <van-sticky type="primary">
      <head-bar
        :title="'分享海报'"
        @onClickLeft="onClickLeft"
      />
    </van-sticky>
    <div class="share-container">
      <div class="share-wrapper">
        <div id="poster" v-if="!success">
          <div calss="head-banner">
            <img style="height:4.5rem; width:100%" class="head-img" :src="url" alt="">
          </div>
          <div class="info-box">
            <p class="ft-b ft-l mb-10">{{userInfo ? userInfo.nickname : ''}}</p>
            <p class="ft-s mb-0">邀请你一起参加活动</p>
          </div>
          <div class="info-box">
            <p class="ft-l ft-b">{{ mice.miceName }}</p>
          </div>
          <div class="info-box">
            <p class="mb-0">时间：{{ mice.startTime.substr(0,10) }} ~ {{ mice.endTime.substr(0,10) }}</p>
            <p class="mb-0">地点：{{ JSON.parse(mice.address).place }}</p>
          </div>
          <div class="center-vertical">
            <vue-qr :text="shareUrl" :size="150" />
          </div>
          <div class="info-box">
            <p>长按识别二维码参与活动</p>
          </div>
        </div>
        <img class="poster" :src="shareImg" alt="" v-else>
        <div class="mask" v-if="!success">
          <van-loading />
        </div>
        <div class="mask-enable" @click.stop="" v-if="!success"></div>
      </div>
    </div>
    <div class="tip-wrappper">
      <p>长按保存相册</p>
      <p>点击右上角可分享链接</p>
    </div>
  </div>
</template>
<script>
import vueQr from 'vue-qr'
import html2canvas from 'html2canvas'
import headBar from '@/components/headBar/headBar'
export default {
  components: { vueQr, headBar },
  data() {
    return {
      shareImg: '',
      success: false,
      url: ''
    }
  },
  computed: {
    mice() {
      return this.$store.state.miceInfo
    },
    shareUrl() {
      return `${window.location.href}`
    },
    userInfo() {
      return this.$parent.$parent.$parent.userInfo
    }
  },
  mounted() {
    this.$http.get(this.$baseURL.index.getBase64() + '?url=' + this.$store.state.miceInfo.posters).then(res => {
      this.url = res
      document.querySelector('.head-img').onload = () => {
        this.createSharePost()
      }
    })
  },
  methods: {
    switchType() {
      this.$emit('switchType', 1)
    },
    createSharePost() {
      html2canvas(document.querySelector('#poster'), { logging: false }).then(canvas => {
        this.shareImg = canvas.toDataURL()
        this.success = true
      })
    },
    onClickLeft() {
      this.$parent.showPoster = false
    }
  }
}
</script>
<style lang="less" scoped>
.share-container{
  min-height: 15.5rem;
  // background: #fff;
  width: 100%;
  padding: 0.8rem;
  .share-wrapper{
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    width: 100%;
    background: #fff;
    box-shadow: 0 0 8px 2px rgba(0,0,0,.1);
    border-radius: 8px;
    overflow: hidden;
    .poster{
      height: 100%;
      width: 100%;
    }
    .head-banner{
      height: 4.5rem;
      .head-img{
        height: 100%;
        width: 100%;
      }
    }
    .info-box{
      font-size: 13px;
      // padding: 15px 0;
      .ft-l{
        font-size: 15px;
      }
      .ft-s{
        font-size: 11px;
      }
      .ft-b{
        font-weight: bold;
      }
      .mb-0{
        margin: 0;
      }
      p{
        padding: 0 15px;
        text-align: center;
      }
    }
  }
  .back{
    margin-bottom: 15px;
    color: #8e8e8e;
    font-size: 13px;
    display: flex;
    align-items: center;
  }
}
.mask{
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  background: #fff;
  z-index: 5;
}
.mask-enable{
  position: fixed;
  height: 100vh;
  width: 100vw;
  z-index: 2005;
  left: 0;
  top: 0;
}
.tip-wrappper {
  text-align: center;
  font-size: 12px;
  color: #686868;
  p {
    margin: 0;
    line-height: 18px;
  }
}
</style>

