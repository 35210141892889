<template>
  <div class="main-container" :style="{ '--themeColor': $store.state.themeInfo.themeColor }">
    <template v-if="!showPoster">
      <div class="head-wraper">
      <div class="wrap-box">
        <img :src="$store.state.miceInfo.posters+'?imageView2/2/w/750'" alt="" class="main-bg">
        <div class="mice-info">
          <div class="mice-name">
            <div class="left">
              {{ $store.state.miceInfo.miceName }}
            </div>
          </div>
          <!-- <div class="sign-num">参与人数：{{ $store.state.miceInfo.applyCount || 0 }}</div> -->
        </div>
        <div class="message-info">
          <!-- <div class="message-item">
            <van-icon name="clock-o" />
            <span class="tag">报名</span>
            <span class="time" v-if="$store.state.miceInfo.regStartTime">{{ $store.state.miceInfo.regStartTime.substr(0, 16) }} - {{ $store.state.miceInfo.registerDeadline.substr(0,16) }}</span>
          </div> -->
          <div class="message-item">
            <van-icon name="clock-o" />
            <span class="tag">活动</span>
            <span class="time" v-if="$store.state.miceInfo.startTime">{{ $store.state.miceInfo.startTime.substr(0, 16) }} - {{ $store.state.miceInfo.endTime.substr(0,16) }}</span>
          </div>
          <div class="message-item message-item_last" @click="showMap">
            <van-icon name="location" />
            <span class="text">{{ address.place }}</span>
          </div>
          <template v-if="phone">
            <div class="message-item message-item_phone" @click="handleCallPhone(phone[0].phone)">
              <div class="item-left">
                <van-icon name="phone" />
                <span class="text">
                  <span v-for="(str, index) in phone[0].name" :key="index">{{ str }}</span>
                </span>
                <span class="phone">{{ phone[0].phone }}</span>
              </div>
              <div class="item-right">
                <van-icon :name="userPhoneShow ? 'arrow-up' : 'arrow-down'" @click.stop="toggleUserList" v-show="phone && phone.length > 1" />
              </div>
            </div>
            <transition name="fade">
              <div class="message-toggle" v-if="userPhoneShow">
                <div class="message-item message-item_phone" v-for="(item, index) in phone" :key="index" v-show="index!==0" @click="handleCallPhone(item.phone)">
                  <div class="item-left">
                    <van-icon name="phone" style="opacity: 0;" />
                    <span class="text">
                      <span v-for="(str, index) in item.name" :key="index">{{ str }}</span>
                    </span>
                    <span class="phone">{{ item.phone }}</span>
                  </div>
                  <div class="item-right">
                    <van-icon name="arrow-up" style="opacity: 0;" />
                  </div>
                </div>
              </div>
            </transition>
          </template>
       
        </div>
        <div class="signed-wrap" v-if="isShowApp">
          <span>已报名</span>
          <span>{{$store.state.miceInfo.applyCount || 0}}人</span>
        </div>
      </div>
    </div>
    <div class="hr-grey"></div>
    <div class="desc-container">
      <div class="label">活动介绍</div>
      <div class="html-wrap" v-html="$store.state.miceInfo.description"></div>
    </div>
    <div class="action-panel">
      <!-- <div class="user-wrapper" @click="showPoster = true">
        <img src="https://sn202108-1305501521.cos.ap-shanghai.myqcloud.com/202204071157196931097.png" alt="">
        <span>生成海报</span>
      </div> -->
      <div class="user-wrapper" @click="goUser">
        <img src="https://sn202108-1305501521.cos.ap-shanghai.myqcloud.com/202111221656207563704.png" alt="">
        <span>个人中心</span>
      </div>
      <van-button class="action sign-action" @click="goToSignUp">
        我要报名
      </van-button>
    </div>
    </template>
   
    <!-- 分享海报 -->
    <share-poster v-else />
    <transition name="slip">
      <div v-if="show" class="share-body">
        <div class="share-container">
          <div class="share-wrapper">
            <div id="poster" v-if="!success" :style="{backgroundImage: `url(${imgData.url})`}">
              <div calss="head-banner" id="head-banner">
                <img class="head-img" style="width:100%;height:11rem" :src="url" alt="">
              </div>
              <div class="qr-img">
                <vue-qr :text="shareUrl" :margin="4" :size="70" />
              </div>
              <!-- <div class="info-box">
                <p>长按识别二维码参与活动</p>
              </div> -->
            </div>
            <img class="poster" :src="shareImg" alt="" v-else>
            <div class="mask" v-if="!success">
              <van-loading />
            </div>
          </div>
        </div>
      </div>
    </transition>
    <transition name="fade">
      <div v-if="show" class="mask-overlay" @click="maskClick"></div>
    </transition>
    <van-dialog 
      v-model="over"
      class="dialog"
      :showConfirmButton="false"
    >
      <div class="over">
        <span>
        <img src="https://sn202108-1305501521.cos.ap-shanghai.myqcloud.com/202112161701059411052.png" alt="" class="over-image">
        </span>
        <span class="text">
          当活动报名已结束
        </span>
      </div>
    </van-dialog>
  </div>
</template>
<script>
// TODO: 重新编写生成图片
import { Toast } from 'vant'
import vueQr from 'vue-qr'
import wx from '@/utils/wx'
import html2canvas from 'html2canvas'
import sharePoster from './components/sharePoster'
export default {
  name: 'indexLite',
  data() {
    return {
      showImg: true,
      options: [
        { name: '微信', icon: 'wechat' }
      ],
      show: false,
      success: false,
      url: '',
      shareImg: '',
      activeCollapse: [],
      over: false, //报名时间截止弹窗
      userPhoneShow: false,
      imgData: {
        bg:''
      },
      registeSuccess: false,
      showPoster: false, // 显示分享海报
      invoiceObj: {},
      isShowApp: false
    }
  },
  components: { vueQr, sharePoster },
  computed: {
    mice() {
      return this.$store.state.miceInfo
    },
    shareUrl() {
      return `${window.location.href}`
    },
    address() {
      return this.mice.address ? JSON.parse(this.mice.address) : ''
    },
    phone() {
      return this.mice.phone ? JSON.parse(this.mice.phone) : ''
    }
  },
  created() {
    this.getMiceInfo()
    // this.getAfterInfo()
  },
  activated() {
    // this.userIsApply()
  },
  filters: {
  },
  methods: {
    createImg() {
      const imgContainer = this.$refs.createImg
      html2canvas(imgContainer).then(canvas => {
        imgContainer.appendChild(canvas)
        this.showImg = true
      })
    },
    onSelect(option) {
      Toast(option.name)
    },
    getMiceInfo() {
      this.$http.get(this.$baseURL.index.getMiceInfo() + '?miceLink=' + this.$route.params.miceLink).then(res => {
        const {posters} = res
        this.imgData.bg = posters
        this.$store.commit('miceInfo/SET_MICE', res)
      })
    },
    getAfterInfo () {
      this.$http.get(this.$baseURL.index.getExternalBackstageMice() +'?miceLink=' + this.$route.params.miceLink ).then(res => {
        this.isShowApp = res.showApplyNum
      })
    },
    goToSignUp() {
      this.$router.push('/signup/' + this.$route.params.miceLink)
      // var data = new Date(this.$store.state.miceInfo.registerDeadline.substr(0,16))
      // var timestamp1 = data.getTime()
      // var timestamp2 = new Date().getTime();
      // var timestamp = timestamp1 - timestamp2;
      // var data1 = new Date(this.$store.state.miceInfo.endTime.substr(0,16))
      // var stamp1 = data1.getTime()
      // var stamp2 = new Date().getTime();
      // var stamp = stamp1 - stamp2;
      // if(timestamp < 0 || stamp < 0){
      //   this.over = true
      //   setTimeout(() => {
      //   this.over = false
      // }, 3000);
      // } else {
      //   // 如果已报名则跳转个人中心
      //   if (this.registeSuccess) {
      //     this.goUser()
      //   } else {
      //     this.$router.push('/signup/' + this.$route.params.miceLink)
      //   }
      // }
    },
    goUser() {
      this.$router.push('/user/' + this.$route.params.miceLink)
    },
    showMap() {
      // wx.openLocation(this.address.lat, this.address.lng, 'TIT 创意园', '广州市海珠区新港中路 397 号')
      wx.openLocation(this.address.lat, this.address.lng,this.address.place, this.address.place)
    },
    createSharePost() {
      setTimeout(() => {
        html2canvas(document.querySelector('#poster'), { logging: false }).then(canvas => {
          this.shareImg = canvas.toDataURL()
          this.success = true
        })
      }, 1000)
    },
    maskClick() {
      if (!this.success) {
        return
      }
      this.show = !this.show
    },
    phoneFilter(val) {
      if (val) {
        return JSON.parse(val)[0].phone
      } else {
        return ''
      }
    },
    handleCallPhone(phone) {
      window.location.href = 'tel://' + phone
    },
    changeActiveCollapse(e) {
      console.log(e)
    },
    toggleUserList() {
      this.userPhoneShow = !this.userPhoneShow
    },
    // 微信openid判断是否已报名
    async userIsApply() {
      if (!this.$store.state.userInfo.openid) {
        return
      }
      let result = await this.$http.post(this.$baseURL.index.openIdIsApply(),{
        openId: this.$store.state.userInfo.openid,
        miceLink: this.$route.params.miceLink
      },1)
      if (result.data) {
        this.registeSuccess = true
      }
    }
  }
}
</script>
<style scoped lang="less">
@themeColor: var(--themeColor);
.main-container{
  height: 100vh;
  padding-bottom: 50px;
  overflow: auto;
  .head-wraper{
    background: #fff;
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;
    overflow: hidden;
    .wrap{
      background: #fff;
      width: 110%;
      height: 200px;
      position: absolute;
      bottom: 0;
      border-radius: 40px;
      z-index: 1;
    }
    .blur-bg{
      position: relative;
      width: 100%;
      height: 200px;
      overflow: hidden;
      object-fit: cover;
      display: flex;
      align-items: center;
      justify-content: center;
      .bg{
        width: 104%;
        height: 104%;
        filter: blur(2px);
      }
      .mask{
        position: absolute;
        top: 0;
        left:0;
        background: rgba(255,255,255,.22);
        height: 100%;
        width: 100%;
        z-index: 1;
      }
    }
    .wrap-box{
      width: 100%;
      z-index: 2;
      .main-bg{
        display: block;
        width: 100%;
        height: 180px;
      }
      .mice-info{
        padding: 10px;
        border-bottom: 1px solid #f4f4f4;
        .mice-name{
          font-size: 16px;
          line-height: 24px;
          font-weight: bold;
          color: #000;
          display: flex;
          justify-content: space-between;
        }
        .sign-num{
          margin-top: 15px;
          font-size: 14px;
          color: #999;
          text-align: right;
        }
      }
      .message-info{
        padding: 10px 0 0;
        font-size: 14px;
        color: #666;
        .arrow{
          float: right;
          margin-top: 7px;
        }
        .message-item{
          margin-bottom: 14px;
          display: flex;
          align-items: flex-start;
          font-size: 14px;
          padding: 0 10px;
          height: 20px;
          &:nth-last-child(1) {
            margin: 0;
            border: none;
          }
          i {
            margin-right: 6px;
            font-size: 16px;
            font-weight: bold;
            color: @themeColor;
          }
          .tag {
            height: 16px;
            display: flex;
            align-items: center;
            margin-right: 6px;
            border: 1px solid @themeColor;
            border-radius: 2px;
            color: @themeColor;
            padding: 0 5px;
            font-size: 10px;
          }
          .time {
          }
          .text{
            padding-top: 1px;
            &::first-line {
              line-height: 1;
            }
          }
        }
        .message-item_last {
          margin-bottom: 4px;
        }
        .message-item_phone {
          margin-bottom: 0;
          height: 32px;
          justify-content: space-between;
          border-bottom: 1px solid #F1EFF2;
          .item-left {
            height: 100%;
            display: flex;
            align-items: center;
            .text {
              height: 32Px;
              min-width: 40px;
              display: flex;
              justify-content: space-between;
              line-height: 32Px;
            }
            .phone {
              margin-left: 20px;
            }
          }
          .item-right {
            height: 100%;
            display: flex;
            align-items: center;
            span {
              margin-right: 20px;
            }
          }
        }
        .message-toggle {
          background: #fff;
        }
      }
      .signed-wrap {
        display: flex;
        justify-content: space-between;
        padding: 14px 10px;
        font-size: 14px;
        color: #666;
        border-top: 1px solid #f4f4f4;
      }
    }
  }
  .sponsor-container{
    padding: 16px 12px;
    background: #fff;
    display: flex;
    margin-bottom: 7px;
    .sponsor-img{
      height: 60px;
      width: 60px;
      border-radius: 8px;
      background: #bfbfbf;
    }
    .sponsor-message{
      height: 60px;
      flex: 1;
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      padding-left: 15px;
      .sponsor-name{
        font-size: 14px;
        color: #333;
      }
      .sponsor-sign{
        font-size: 13px;
        color: #999;
      }
      .sponsor-det{
        font-size: 12px;
        color: #999;
        span{
          display: inline-flex;
          width: 100px;
          align-items: center;
        }
      }
    }
  }
  .desc-container{
    background: #fff;
    padding: 10px 12px;
    font-size: 14px;
    .label {
      padding: 10px;
      font-size: 16px;
      font-weight: bold;
      color: #000;
      border-bottom: 1px solid #f4f4f4;
      position: relative;
      &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 10px;
        width: 3PX;
        height: 21px;
        background-color: @themeColor;
        display: block;
      }
    }
    /deep/ img{
      max-width: 100%;
      height: auto;
    }
  }
  .action-panel{
    position: fixed;
    bottom: 0;
    left: 0;
    display: flex;
    height: 50px;
    width: 100%;
    box-shadow: 0 0 5px 2px rgba(0,0,0,.1);
    .user-wrapper {
      position: relative;
      width: 93px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background: #fff;
      font-size: 13px;
      color: #212121;
      img {
        width: 18px;
        margin-bottom: 4px;
      }
      &:nth-of-type(1):after {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        content: '';
        height: 38px;
        border-right: 1px solid #B2B1B1;
      }
    }
    .action{
      flex: 1;
      height: 100%;
      font-size: 16px;
      color: #333;
      border: none;
      border-radius: 0;
      .action-content-text{
        display: flex;
        align-items: center;
      }
    }
    .sign-action{
      background: @themeColor;
      color: #fff;
    }
  }
}
#poster {
  background-size: 100% 100%;
  position: relative;
  .qr-img {
    position: absolute;
    right: 0;
    bottom: 0;
  }
}
.share-container{
  min-height: 11.4rem;
  // background: #fff;
  width: 100%;
  // padding: 0.8rem;
  .share-wrapper{
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    width: 100%;
    background: #fff;
    // box-shadow: 0 0 8px 2px rgba(0,0,0,.1);
    // border-radius: 8px;
    overflow: hidden;
    .mask{
      position: absolute;
      left: 0;
      top: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      width: 100%;
      background: #fff;
      z-index: 5;
    }
    .poster{
      height: 100%;
      width: 100%;
    }
    .head-banner{
      height: 4.5rem;
      .head-img{
        height: 100%;
        width: 100%;
      }
    }
    .info-box{
      font-size: 13px;
      // padding: 15px 0;
      .ft-l{
        font-size: 15px;
      }
      .ft-s{
        font-size: 11px;
      }
      .ft-b{
        font-weight: bold;
      }
      .mb-0{
        margin: 0;
      }
      p{
        padding: 0 15px;
        text-align: center;
      }
    }
  }
  .back{
    margin-bottom: 15px;
    color: #8e8e8e;
    font-size: 13px;
    display: flex;
    align-items: center;
  }
}
.share-body {
  width: 320px;
  height: 450px;
  overflow: hidden;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 99;
}
.mask-overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 98;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
}
.slip-enter,
.slip-leave-to{
  opacity: 0;
  transform: translate(-50%, -50%) scale(.5);
  // top: 100%;
}
.fade-enter,
.fade-leave-to{
  opacity: 0;
}
.slip-enter-active,
.slip-leave-active,
.fade-enter-active,
.fade-leave-active{
  transition: .3s;
}
.dialog {
  width: 300px;
  height: 150px;
 .over{
   margin-top: 10%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    // width: 200px;
    // height: 100px;
    .text {
    margin-top: 10%;
    }
  .over-image {
    width: 35px;
    height: 35px;
  }
 }
}
#img {
  width: 60vw;
  height: 70vh;
  position: relative;
  background-size: 100% 100%;
  img {
    position: absolute;
    right: 10px;
    bottom: 10px;
  }
  canvas {
    width: 100%;
    height: 100%;
  }
}
.hr-grey {
  height: 10px;
  background-color: #f0f0f0;
}
.phone-item {
  display: flex;
  align-items: center;
  font-size: 14px;
  padding: 4px 10px;
  i {
    margin-right: 6px;
    font-size: 16px;
    font-weight: bold;
    color: @themeColor;
  }
  .hideIcon {
    color: #fff;
  }
  .name {
    margin: 0 8px 0 0;
  }
}
.phone-item_border {
  border: 1px solid #D3D3D3;
  border-bottom: none;
  &:nth-last-of-type(1) {
    border-bottom: 1px solid #D3D3D3;
  }
}
/deep/.van-cell {
  padding: 0 10px 0 0;
  line-height: none;
}
/deep/.van-collapse-item__content {
  padding: 0;
  // border: 1px solid #D3D3D3;
}
</style>

