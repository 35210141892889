<template>
  <div class="news-wrapper">
    <!-- 嘉宾 -->
    <div class="news-head">
      <span>大会嘉宾</span>
      <span @click="goMore">更多<van-icon name="arrow" /></span>
    </div>

    <!-- Swiper -->
    <div class="swiper-container">
      <div class="swiper-wrapper">
        <div class="swiper-slide" v-for="(item, index) in userList.filter(item => item.name)" :key="index">
          <!-- <img
            src="https://sn202108-1305501521.cos.ap-shanghai.myqcloud.com/202306072000232167646.png"
            alt=""
          /> -->
          <div class="txt-wrapper">
            <div class="username">{{item.name}}</div>
            <div class="desc van-ellipsis">{{item.company}}</div>
          </div>
        </div>
      </div>
      <!-- Add Pagination -->
      <!-- <div class="swiper-pagination"></div> -->
    </div>
  </div>
</template>

<script>
import userList from '../data'
import Swiper from "swiper/js/swiper.js";
import "swiper/css/swiper.css";
import { Toast } from 'vant';
export default {
  name: "UserContent",
  data() {
    return {
      userList: userList
    };
  },
  mounted() {
    this.initSwiper();
  },

  methods: {
    initSwiper() {
      this.swiper = new Swiper(".swiper-container", {
        slidesPerView: 3,
        spaceBetween: 30,
        centeredSlides: true,
        loop: true,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        autoplay: {
          delay: 2000,
          disableOnInteraction: false,
        },
      });
    },
    goMore() {
    //   Toast('敬请期待')
    //   return 
      this.$router.push({ path: `/index/userList/${this.$store.state.miceInfo.miceLink}` });
    }
  },
};
</script>

<style lang="less" scoped>
.news-wrapper {
  padding: 0 15px 20px;
  .news-head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0 20px;
    span:nth-of-type(1) {
      font-size: 18px;
      font-weight: bold;
      color: #fff;
    }
    span:nth-of-type(2) {
      font-size: 14px;
      color: #fff;
      display: flex;
      align-items: center;
      img {
        width: 7px;
        margin-left: 4px;
      }
    }
  }
}

.swiper-container {
  width: 100%;
  height: auto;
  padding: 5px 0 10px 40px;
  margin-left: auto;
  margin-right: auto;
}
.swiper-slide {
  min-width: 180px;
  height: 50px;
  text-align: center;
  font-size: 18px;
  // background: #e8ebfc;
  // background: #2125C3;
  background: url(https://sn202108-1305501521.cos.ap-shanghai.myqcloud.com/202306071734095895069.jpeg);
  background-size: 100%;
  background-position: top;
  color: white;
  border-radius: 4px;
  padding: 2px 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  display: flex;
  // flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  img {
    width: 30px;
    height: 30px;
    border-radius: 50%;
  }
  .txt-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 33%;
    flex: 1;
    width: 65%;
    .username {
      font-size: 14px;
    }
    .desc {
      font-size: 12px;
      // color: #666666;
      width: 100%;
      text-align: left;
    }
  }
}
#progressEffect {
  position: absolute;
  z-index: 10;
  top: 20px;
  left: 20px;
}
</style>
