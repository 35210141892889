import utils from './utils'
import http from './http'
import baseURL from './baseURL/baseURL'
let wx = require('weixin-js-sdk')

let weixin = {
    getAppID() {
        return process.env.VUE_APP_WX_APPID
    },
    async config(shareInfo) {
        let that = this
        let data = await http.postParams(baseURL.weixin.getSignInfo(), {url: utils.getWeiXinUrl() || window.location.href.split('#')[0]})
        console.log(process.env.VUE_APP_WX_APPID,'appid3')
        wx.config({
            debug: process.env.NODE_ENV === 'development' ? true : false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
            appId: process.env.VUE_APP_WX_APPID, // 必填，公众号的唯一标识 正式appid
            timestamp: data.timestamp, // 必填，生成签名的时间戳
            nonceStr: data.nonceStr, // 必填，生成签名的随机串  
            signature: data.signature,// 必填，签名
            jsApiList: ['updateAppMessageShareData', 'updateTimelineShareData', 'onMenuShareWeibo', 'onMenuShareQZone', 'openLocation', 'getLocation'] // 必填，需要使用的JS接口列表
        })
        wx.ready(() => {
            if (typeof(shareInfo) === "object") {
                let tempParam = {
                    title: shareInfo.shareTitle,
                    desc: shareInfo.shareContent,
                    link: window.location.href.split('#')[0],
                    imgUrl: shareInfo.sharePosters,
                    success() {
                        // const user = utils.getCookie('user')
                        // console.log('分享成功', res)
                        // http.post(baseURL.index.recordVisit(), {
                        //     miceLink: store.state.miceInfo.miceLink,
                        //     openId: typeof(user) === 'string' ? 'normal-persoon' : (user === null ? 'normal-person' : user.openid),
                        // }, 1).then(() =>{
                        //     //
                        // })
                        console.log('分享确认')
                    },
                    cancel() {
                        console.log('分享取消')
                    }
                }
                wx.updateAppMessageShareData(tempParam)
                wx.updateTimelineShareData(tempParam)
                wx.onMenuShareWeibo(tempParam)
                wx.onMenuShareQZone(tempParam)
            }
        })
        wx.error((res) => {
            console.warn('error:', res)
        })
    },
    openLocation(latitude, longitude,name, desc) {
        wx.openLocation({
            latitude: latitude, // 纬度，浮点数，范围为90 ~ -90
            longitude: longitude, // 经度，浮点数，范围为180 ~ -180。
            name: name, // 位置名
            address: desc, // 地址详情说明
            scale: 28, // 地图缩放级别,整形值,范围从1~28。默认为最大
            infoUrl: '' // 在查看位置界面底部显示的超链接,可点击跳转
        })
    }
}

export default weixin